import React from "react";

import { Typography, Container, Divider, Button } from "@mui/material";

function Home() {
  return (
    <>
      <img
        src="/images/banner.jpg"
        alt="Banner"
        style={{ width: "100%", height: "40vh", objectFit: "cover" }}
      />

      <Container>
        <Typography variant="subtitle1" fontStyle={"italic"}>
          The CTF association at TU/e.
        </Typography>
        
        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          Privilege Escalation Training Night!
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          Join us for a hands-on workshop diving into Privilege Escalation
          and Lateral Movement techniques! Learn how attackers exploit vulnerabilities
          to gain elevated access and move through systems in a CTF environment.
          <br />
          <br />
          Here's what we'll cover:
          { 
          <ul>
            <li>
              🔐 <b>Privilege Escalation Basics</b> - Sudo, SUID, capabilities, and more
            </li>
            <li>
              📜 <b>System Enumeration</b> - Identify vulnerabilities in Linux systems
            </li>
            <li>
              🛠️ <b>Automated Tools</b> - How to streamline your approach using the right tools
              common service vulnerabilities with ease
            </li>
            <li>
              🐧 <b>Deep Dive: Linux</b> - Kernel exploits and Docker escapes
            </li>
            <li>
              💥 <b>Command Injection, SQL Injection, XSS, ...</b> - Creative
              exploitation of web-apps
            </li>
          </ul> }
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <i>Bring your laptop</i> 💻{" "}
          <i>
            and your curiosity, we'll provide the rest! No prior experience
            needed, this workshop is perfect for beginners who want to take the
            first step toward mastering cybersecurity. Spaces are limited!
            Secure your spot now by filling out the participation form below!
          </i>
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Date:</b> February 12th (Wednesday)
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Location:</b> [TU/e room TBA]
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          <b>Schedule:</b> <br />
          18:00 - 20:00 <br />
        </Typography>

        <Button
          target="_blank"
          href="https://forms.office.com/e/7tNRS0R8ks"
          rel="noreferrer"
          variant="contained"
          style={{ marginTop: "2vh" }}
        >
          SIGN UP
        </Button>

        <Divider
          variant="middle"
          style={{ marginTop: "3vh", marginBottom: "3vh" }}
        />

        <Typography variant="h4" style={{ marginTop: "2vh" }}>
          Goals & Vision
        </Typography>

        <Typography variant="body1" style={{ marginTop: "2vh" }}>
          In our eyes, CTFs are one of the best aspects of cybersecurity. They
          provide a great way to learn, but can also be described as a purely
          competitive sport, thereby providing value for individuals of all
          skill levels in the field. Not only that, but they also bring people
          together, which is a priceless quality in any technical environment.
          <br />
          <br />
          The board of E.S.H.A. Trojan not only aims to establish a medium
          through which people of any background can start participating in
          CTFs, but also to create a thriving community of hackers to share
          experiences with.
          <br />
          <br />
          We have subdivided this vision into four separate goals:
          <ol>
            <li>
              Setting up structural training aimed to improve your ability to
              solve CTF challenges
            </li>
            <li>
              Providing opportunities for members to participate in CTFs and
              making them more accessible as a whole
            </li>
            <li>
              Finding ways to connect people that share a passion for hacking
            </li>
            <li>Organizing a large scale annual CTF of our own</li>
          </ol>
          We wish to foster this community and cannot wait to see it grow. In
          the rest of our policy plan, we will convince you on how we will
          achieve these goals. But more importantly, we hope to persuade you to
          join E.S.H.A. Trojan.
          <br />
          <br />
          Sincerely,
          <br />
          The 0th Board of E.S.H.A. Trojan
        </Typography>
      </Container>
    </>
  );
}

export default Home;
